<template>
  <v-dialog
      v-model="isOpen"
      @input="close()"
      @click:outside="close()"
      :width="typeof big!=='undefined' ? 1280 : typeof mid !=='undefined' ? 800 : typeof width !=='undefined' ? width : 425"
      
      scrollable
      :persistent="cancelNotClose===true"
  >
    <template v-slot:activator="{  }" v-if="!!$slots.activator">
      <div @click="isOpen=true" class="d-inline-block">
        <slot name="activator"/>
      </div>
    </template>
    <v-card v-if="typeof acceptName!=='undefined'" class="dialogView">
      <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <slot name="barActions"/>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider/>
      <v-card-text class="_warning" v-if="content">
         <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          {{content}}
        </v-alert>
      </v-card-text>
      <v-card-actions class="footer">
        <v-btn v-if="typeof hideCancelAction==='undefined'" text @click="close()">Отмена</v-btn>
        <v-spacer/>
        <v-btn text @click="$emit('acceptAction')" :loading="loading" color="primary">{{acceptName}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="dialogView">
      <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <slot name="barActions"/>
        <v-btn v-if="typeof hideCancelAction==='undefined'" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear v-if="loading" indeterminate class="position-absolute"/>
      <div v-if="!!$slots.header" class="header px-3 pt-2 pb-3 body-2">
        <slot name="header"/>
      </div>
      <v-divider v-if="!!$slots.content"/>
      <div v-if="!!$slots.content" :class="(typeof contentFullSize!=='undefined' ? '' : 'pa-4')+' overflow-y-auto body-2'">
        <slot name="content"/>
      </div>
      <v-divider v-if="!!$slots.content && !!$slots.actions"/>
      <v-card-actions v-if="!!$slots.actions" class="footer">
        <v-btn v-if="typeof hideCancelAction==='undefined'" text @click="close()">Отмена</v-btn>
        <v-spacer/>
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'open',
    'loading',
    'title',
    'content',
    'mid',
    'big',
    'contentFullSize',
    'acceptName',
    'cancelNotClose',
    'hideCancelAction',
    'width'
  ],
  data: () => ({
    isOpen:false
  }),
  watch: {
    open(){
      this.isOpen = this.open;
    },
  },
  methods:{
    close(){
      if(typeof this.acceptName!=='undefined')
        this.isOpen = false;
      this.$emit('cancel')
    }
  }
}
</script>


<style scoped lang="scss">
.dialogView{
  > .bar,
  > .header,
  > .footer{
    background: #F7F7F7 !important;
  }
}
._warning {
  padding: 0 !important;
  border-radius: 0 !important;
  .v-alert {
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}
</style>